const generalUiTexts = {
	title: {
		da: 'Training Games',
		en: 'Training Games',
		se: 'Training Games',
		de: 'Training Games',
		pl: 'Training Games',
		ro: 'Training Games',
		ua: 'Training Games'
	},
	subtitle: {
		da: 'Spilbaseret læring & træning',
		en: 'Game based learning & training',
		se: 'Spelbaserat lärande och träning',
		de: 'Spielbasiertes Lernen und Training',
		pl: 'Nauka i trening oparty na grach',
		ro: 'Învățare și antrenament bazat pe joc',
		ua: 'Навчання та тренування на основі гри'
	},
	loading: {
		da: 'Loader',
		en: 'Loading',
		se: 'Läser in',
		de: `Wird geladen`,
		pl: `Ładowanie`,
		ro: `Se încarcă`,
		ua: `Навантажувач`
	},

	/* Platform is closed */
	platformClosedTitle: {
		da: 'Siden er midlertidigt lukket',
		en: 'The site is temporarily closed',
		se: 'Webbplatsen är tillfälligt stängd',
		de: `Die Site ist vorübergehend geschlossen`,
		pl: `Strona jest tymczasowo zamknięta`,
		ro: `Site-ul este temporar închis`,
		ua: `Сайт тимчасово закритий`
	},
	platformClosedText: {
		da: 'Vi opdaterer koden og er snart oppe igen.',
		en: `We are updating the code and will be up again soon.`,
		se: `Vi uppdaterar koden och kommer snart upp igen.`,
		de: `Wir aktualisieren den Code und sind bald wieder verfügbar.`,
		pl: `Aktualizujemy kod i wkrótce znów będzie dostępny.`,
		ro: `Actualizăm codul și vom reveni în curând.`,
		ua: `Ми оновлюємо код і незабаром знову буде доступний.`
	},
	/* Planned downtime */
	popupPlannedDownTime: {
		title: {
			da: 'Planlagt nedetid',
			en: 'Planned downtime',
			se: 'Planerad driftstopp',
			de: `Geplante Ausfallzeiten`,
			pl: `Planowany przestój`,
			ro: `Timp de nefuncționare planificat`,
			ua: `Плановий простой`
		},
		text: {
			da: `På grund af planlagt vedligeholdelse, bliver siden midlertidigt lukket %startDate% kl %startTime% (dansk tid).
				<br /><br />Vi forventer en nedetid på max %duration% minutter.`,
			en: `Due to a scheduled maintenance, the site will be temporarily closed %startDate% at %startTime% (Danish time).
				<br /><br />We expect a downtime of max %duration% minutes.`,
			se: `På grund av planerat underhåll kommer webbplatsen tillfälligt att stängas %startDate% kl. %startTime% (dansk tid).
  			<br /><br />Vi förväntar oss en driftstopp på max %duration% minuter.`,
			de: `Aufgrund geplanter Wartungsarbeiten ist die Site %startDate% um %startTime% vorübergehend geschlossen (Dänische Zeit).
  			<br /><br />Wir erwarten eine Ausfallzeit von maximal %duration% Minuten.`,
			pl: `Ze względu na planowaną konserwację strona internetowa zostanie tymczasowo zamknięta %startDate% r. o godzinie %startTime% (Czas duński).
  			<br /><br />Przewidujemy, że przestój potrwa maks. %duration% minut.`,
			ro: `Din cauza întreținerii programate, site-ul va fi închis temporar %startDate% la %startTime% (ora daneză).
  			<br /><br />Ne așteptăm la o perioadă de întrerupere de maximum %duration% minute.`,
			ua: `Через планове технічне обслуговування сайт буде тимчасово закрито %startDate% о %startTime% ранку (датський час).
  			<br /><br />Ми очікуємо, що час простою не перевищує %duration% хвилин.`
		},
		onlyPlayers: {
			da: `Bemærk: Nedetiden vil kun påvirke spillere, siden vil stadig være tilgængelig for facilitatorer og adminstratorer.`,
			en: `Note: The downtime will only affect players, the site will continue to be available for facilitators and administrators.`,
			se: `Obs: Avbrottet kommer bara att påverka spelare, sidan kommer att fortsätta att vara tillgänglig för facilitatorer och administratörer.`,
			de: `Hinweis: Die Ausfallzeit betrifft nur Spieler. Für Moderatoren und Administratoren steht die Site weiterhin zur Verfügung.`,
			pl: `Uwaga: przerwa w działaniu serwisu dotyczy wyłącznie graczy. Strona będzie nadal dostępna dla moderatorów i administratorów.`,
			ro: `Notă: Perioada de nefuncționare va afecta doar jucătorii, site-ul va continua să fie disponibil pentru facilitatori și administratori.`,
			ua: `Примітка: час простою вплине лише на гравців, сайт і надалі буде доступний для фасилітаторів і адміністраторів.`
		},
		today: {
			da: 'i dag',
			en: 'today',
			se: `i dag`,
			de: `heute`,
			pl: `dzisiaj`,
			ro: `astăzi`,
			ua: `сьогодні`
		},
		tomorrow :{
			da: 'i morgen',
			en: 'tomorrow',
			se: `i morgon`,
			de: `morgen`,
			pl: `jutro`,
			ro: `mâine`,
			ua: `завтра`
		}
	}
};

export {
	generalUiTexts
};